<template>
  <div class="hello">
    <h1>{{game}}</h1>
    <div>
      Senaste resultat:<br>
      {{result[game].lastResult}}<br>
    </div>
    <JokerBlock
      v-bind:rows="result[game].firstTwo" 
      heading="Bästa första par" 
      anchor="firstTwo" 
      prefix="" 
      suffix="-----"
    />
    <JokerBlock
      v-bind:rows="result[game].lastTwo"
      heading="Bästa sista par"
      anchor="lastTwo"
      prefix="-----"
      suffix=""
    />

    <JokerBlock
      v-bind:rows="result[game].firstThree"
      heading="Bästa första tre  (XXX----)"
      anchor="firstThree"
      prefix="" 
      suffix="----"
    />
    <JokerBlock
      v-bind:rows="result[game].lastThree"
      heading="Bästa sista tre  (----XXX)"
      anchor="lastThree"
      prefix="----" 
      suffix=""
    />

    <JokerBlock
      v-bind:rows="result[game].firstFour"
      heading="Bästa första fyra  (XXXX---)"
      anchor="firstFour"
      prefix="" 
      suffix="---"
    />
    <JokerBlock
      v-bind:rows="result[game].lastFour"
      heading="Bästa sista fyra  (---XXXX)"
      anchor="lastFour"
      prefix="---"
      suffix=""
    />

    <JokerBlock
      v-bind:rows="result[game].firstFive"
      heading="Bästa första fem  (XXXXX--)"
      anchor="firstFive"
      prefix="" 
      suffix="--"
    />
    <JokerBlock
      v-bind:rows="result[game].lastFive"
      heading="Bästa sista fem  (--XXXXX)"
      anchor="lastFive"
      prefix="--"
      suffix=""
    />

    <JokerBlock
      v-bind:rows="result[game].firstSix"
      heading="Bästa första sex  (XXXXXX-)"
      anchor="firstSix"
      prefix=""
      suffix="-"
    />
    <JokerBlock
      v-bind:rows="result[game].lastSix"
      heading="Bästa sista sex  (-XXXXXX)"
      anchor="lastSix"
      prefix="-"
      suffix=""
    />

    <JokerBlock
      v-bind:rows="result[game].firstSeven"
      heading="Lika rader  (XXXXXXX)"
      anchor="firstSeven"
      prefix="" 
      suffix=""
    />

    <JokerBlock
      v-bind:rows="result[game].middleThree"
      heading="Bästa tal i mitten (--XXX--)"
      anchor="middleThree"
      prefix="--"
      suffix="--"
    />

    <JokerBlock
      v-bind:rows="result[game].middleFive"
      heading="Bästa tal i mitten 5 siffror (-XXXX-)"
      anchor="middleFive"
      prefix="-"
      suffix="-"
    />

    <a name="rows" />
    <h2>Dragningar - {{result[game].rows.length}} rader</h2>
    <ol>
      <ul :key="item.id" v-for="item in result[game].rows">
        <b>{{item.date ? item.date : item.rank}})</b>&nbsp;
        ( {{item.numbers}} )
      </ul>
    </ol>

    <a name="stats" />
    <h2>Statistik</h2>
    <pre>
{{result[game].stats}}
    </pre>
  </div>
</template>

<script>
//import { ref } from "vue";
import JokerBlock from "./JokerBlock.vue";
import result from "../result.json";

export default {
  data() {
    return {
      result: result,
    };
  },
  components: {
    JokerBlock,
  },
  props: ["game"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 20px 0 0;
}

.hello {
  padding-top: 0px;
}

a {
  color: #42b983;
}
</style>
