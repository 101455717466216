<template>
  <div>
    <a :name="anchor" />
    <h2>{{heading}}</h2>
    <b>Placering</b>) Antal: nummer
    <ol>
      <ul :key="row.index" v-for="row in lines(rows, prefix, suffix)">
        <b>{{row.index}})</b>&nbsp;
        {{row.line.replace(':', ':&nbsp;&nbsp;&nbsp;')}}&nbsp;
      </ul>
    </ol>
  </div>
</template>

<script>
import eol from "eol";
export default {
  props: ["heading", "anchor", "rows", "prefix", "suffix"],
  methods: {
    lineNumbers: function(rows) {
      let output = "";
      eol
        .split(rows)
        .filter(line => line)
        .forEach(
          (line, index) => (output += index + 1 + "| " + line + eol.crlf)
        );
      return output;
    },
    lines: function(rows, prefix, suffix) {
      const result = [];
      eol
        .split(rows)
        .filter(line => line)
        .forEach((line, index) => {
          let [cnt, ] = line.split(":");
          cnt = parseInt(cnt);
          const [, nums] = line.split(":");
          if (cnt > 1) {
            result.push({ index: index + 1, line: cnt + ":  " + prefix + nums.trim() + suffix});
          }
                 });
        return result;    
    }
  }
};
</script>