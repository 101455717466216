<template>
  <div id="app">
    <div class="menu">
      <button :class= "[game =='lotto' ? 'active' : '']" @click="game='lotto';filter=''">Lotto</button>
      <button :class= "[game =='joker' ? 'active' : '']" @click="game='joker';filter=''">Joker</button>
      <button :class= "[game =='eurojackpot' ? 'active' : '']" @click="game='eurojackpot';filter=''">Eurojackpot</button>
      <button :class= "[game =='vikinglotto' ? 'active' : '']" @click="game='vikinglotto';filter=''">Vikinglotto</button>
      <!-- <button :class= "[game =='euromillions' ? 'active' : '']" @click="game='euromillions';filter=''">EuroMillions</button> -->
      <input v-model="filter" placeholder="Filter, t.ex.:  15 20"/> <span style="cursor: pointer;" @click="filter = ''">x</span>
      <div class="menu-links">
        <div v-if="game === 'joker'">
          <a href="#firstTwo">Första par</a>
          &nbsp;|&nbsp;
          <a href="#lastTwo">Sista par</a>
          &nbsp;|&nbsp;
          <a href="#firstThree">Första tre</a>
          &nbsp;|&nbsp;
          <a href="#lastThree">Sista tre</a>
          &nbsp;|&nbsp;
          <a href="#firstFour">Första fyra</a>
          &nbsp;|&nbsp;
          <a href="#lastFour">Sista fyra</a>
          &nbsp;|&nbsp;
          <a href="#firstFive">Första fem</a>
          &nbsp;|&nbsp;
          <a href="#lastFive">Sista fem</a>
          <br/>
          <a href="#firstSix">Första sex</a>
          &nbsp;|&nbsp;
          <a href="#lastSix">Sista sex</a>
          &nbsp;|&nbsp;
          <a href="#firstSeven">Lika rader</a>
          &nbsp;|&nbsp;
          <a href="#middleThree">Bästa 3 i mitten</a>
          &nbsp;|&nbsp;
          <a href="#middleFive">Bästa 5 i mitten</a>
          &nbsp;|&nbsp;
          <a href="#rows">Dragningar</a>
          &nbsp;|&nbsp;
          <a href="#stats">Statistik</a>
        </div>
        <div v-else>
          <a href="#stats">Statistik</a>
          &nbsp;|&nbsp;
          <a href="#single">Bästa nummer</a>
          &nbsp;|&nbsp;
          <a href="#pair">Bästa par</a>
          &nbsp;|&nbsp;
          <a href="#trio">Bästa trios</a>
          &nbsp;|&nbsp;
          <a href="#tetris">Bästa fyrtal</a>
          <span v-if="game !== 'eurojackpot'">
            &nbsp;|&nbsp;
            <a href="#penta">Bästa femtal</a>
          </span>
          <span v-if="game === 'eurojackpot'">
            &nbsp;|&nbsp;
            <a href="#starRows">Stjärnnummer</a>
          </span>
          &nbsp;|&nbsp;
          <a href="#rows">Dragningar</a>
        </div>
      </div>
    </div>
    <div class="content">
      <Joker v-bind:game="game" v-if="game === 'joker'" />
      <Eurojackpot v-bind:game="game" v-bind:filter="filter" v-else />
    </div>
  </div>
</template>

<script>
import Eurojackpot from "./components/EurojackpotComponent.vue";
import Joker from "./components/JokerComponent.vue";

export default {
  name: "App",
  data() {
    return {
      game: "lotto",
      filter: ""
    };
  },
  components: {
    Eurojackpot,
    Joker
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin-top: 20px;
}

button {
  margin-right: 0.5em;
  font-size: 1.3em;
}

button.active {
  color:red;
}
.content {
  overflow: auto;
  margin-left:1em;
  width: 80%;
  height: 80%;
  position: absolute;
}

pre {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  margin-left: 4em;
  font-size:1em;
}

.menu {
  padding-left: 20px;
  margin-top:5px;
  margin-bottom: 10px;
  background-color: white;
}

.menu-links {
  font-size:1.4em;
  margin-top:0.5em;
}

</style>
