<template>
  <div class="hello">
    <h1>{{game}}</h1>
    <div>
      Senaste resultat:<br>
      {{result[game].lastResult}}<br>
    </div>
    <a name="stats" />
    <h2>Statistik</h2>
    <pre>
{{result[game].stats}}
    </pre>

    <a name="single" />
    <h2>Bästa nummer</h2>
    <ol>
      <ul :key="item.id" v-for="item in filterRows(filter, result[game].bestSingle, rowLimit)">
        <b>{{item.rank}})&nbsp;</b>
        {{ item.count }}:&nbsp;&nbsp;&nbsp;( {{item.numbers.join(', ')}} )
      </ul>
    </ol>

    <a name="pair" />
    <h2>Bästa par - {{filterRows(filter, result[game].bestPairs, rowLimit).length}} stycken</h2>
    <ol>
      <ul :key="item.id" v-for="item in filterRows(filter, result[game].bestPairs, rowLimit)">
        <b>{{item.rank}})&nbsp;</b>
        {{ item.count }}:&nbsp;&nbsp;&nbsp;( {{item.numbers.join(', ')}} )
      </ul>
    </ol>

    <a name="trio" />
    <h2>Bästa trippel - {{filterRows(filter,result[game].bestTrios, rowLimit).length}} stycken</h2>
    <ol>
      <ul :key="item.id" v-for="item in filterRows(filter,result[game].bestTrios, rowLimit)">
        <b>{{item.rank}})&nbsp;</b>
        {{ item.count }}:&nbsp;&nbsp;&nbsp;( {{item.numbers.join(', ')}} )
      </ul>
    </ol>

    <a name="tetris" />
    <h2>Bästa kvadrupel - {{filterRows(filter,result[game].bestTetris, rowLimit).length}} stycken</h2>
    <ol>
      <ul :key="item.id" v-for="item in filterRows(filter, result[game].bestTetris, rowLimit)">
        <b>{{item.rank}})&nbsp;</b>
        {{ item.count }}:&nbsp;&nbsp;&nbsp;( {{item.numbers.join(', ')}} )
      </ul>
    </ol>
    <div v-if="result[game].bestPentas !== undefined && result[game].bestPentas.length > 0 && game !== 'eurojackpot'">
      <a name="penta" />
      <h2>Bästa femtal - {{filterRows(filter,result[game].bestPentas, rowLimit).length}} stycken</h2>
      <ol>
        <ul :key="item.id" v-for="item in filterRows(filter, result[game].bestPentas, rowLimit)">
          <b>{{item.rank}})&nbsp;</b>
          {{ item.count }}:&nbsp;&nbsp;&nbsp;( {{item.numbers.join(', ')}} )
        </ul>
      </ol>
    </div>

    <div v-if="result[game].bestHexas !== undefined">
      <a name="hexa" />
      <h2>Bästa sextal - {{filterRows(filter,result[game].bestHexas, rowLimit).length}} stycken</h2>
      <ol>
        <ul :key="item.id" v-for="item in filterRows(filter, result[game].bestHexas, rowLimit)">
          <b>{{item.rank}})&nbsp;</b>
          {{ item.count }}:&nbsp;&nbsp;&nbsp;( {{item.numbers.join(', ')}} )
        </ul>
      </ol>
    </div>
    <div v-if="result[game].bestSeptas !== undefined">
      <a name="septa" />
      <h2>Bästa sjutal - {{filterRows(filter,result[game].bestSeptas, rowLimit).length}} stycken</h2>
      <ol>
        <ul :key="item.id" v-for="item in filterRows(filter, result[game].bestSeptas, rowLimit)">
          <b>{{item.rank}})&nbsp;</b>
          {{ item.count }}:&nbsp;&nbsp;&nbsp;( {{item.numbers.join(', ')}} )
        </ul>
      </ol>
    </div>

    <div v-if="result[game].starRows !== undefined">
      <a name="starRows" />
      <h2>Stjärnnummer-dragningar - {{filterRows(filter, result[game].starRows, 0).filter(r => r.date >= '2022-03-25').length}} rader</h2>
      <div>Efter 11 och 12 infördes 2022-03-25</div>
      <ol>
        <ul :key="item.id" v-for="item in filterRows(filter, result[game].starRows, 0).filter(r => r.date >= '2022-03-25')">
          <b>{{item.date ? item.date : item.rank}})</b>&nbsp;
          ( {{item.numbers.join(', ')}} ) 
        </ul>
      </ol>
    </div>

    <a name="rows" />
    <h2>Dragningar - {{filterRows(filter, result[game].rows, 0).length}} rader</h2>
    <ol>
      <ul :key="item.id" v-for="item in filterRows(filter, result[game].rows, 0)">
        <span style="display: inline-block;width: 19em"><b>{{item.date ? item.date : item.rank}})</b>&nbsp;
        ( {{item.numbers.join(', ')}} )</span><span v-if="result[game].starRows !== undefined" style="margin-left: -1.5em;">⭐️ ({{result[game].starRows[item.rank-1].numbers.join(', ')}})</span>
      </ul>
    </ol>
  </div>
</template>

<script>
import result from "../result.json";

export default {
  data() {
    return {
      rowLimit: 1000,
      result: result,
    };
  },
  props: ["game", "filter"],
  methods: {
    filterRows: function (filter, rows, limit) {
      if (rows !== undefined) {
        const filterNumbers = (filter === "" ? [] : filter.split(/[,\s]/))
          .filter((n) => n !== "")
          .map((n) => parseInt(n))
          .filter((n) => n > 0);
        const result = rows.filter((row) => {
          if (filterNumbers.length === 0) {
            return true;
          } else {
            return filterNumbers.every((i) => row.numbers.includes(i));
          }
        });
        if (limit > 0) {
          return result.slice(0, limit);
        } else {
          return result;
        }
      } else {
        return [];
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 20px 0 0;
}

.hello {
  padding-top: 0px;
}

a {
  color: #42b983;
}
</style>
